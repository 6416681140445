import { addLookupEntry, deleteLookupEntry, updateLookupEntry } from "api/lookup";
import Checkbox from "components/global/Checkbox";
import Delete from "components/global/Delete";
import List from "components/global/List";
import SuperSelect from "components/global/SuperSelect";
import { t } from "locale/dictionary";
import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { superSelectOperationTypes } from "utilities/constants";
import { getAllListDisplayValues, getAllLookupValuesForSource } from "utilities/lookup";

// Component for customizing lookups
export default function Lookups() {
  const configurableLookups = [
    { id: "ActionTypes", displayValue: "Action Types" },
    { id: "RecordSubtypes", displayValue: "Record Subtypes" },
    { id: "ListTypes", displayValue: "List Types" },
    { id: "ReferenceTypes", displayValue: "Reference Types" },
    { id: "RecordTypes", displayValue: "Record Types" },
    { id: "Statuses", displayValue: "Statuses" },
    { id: "Substatuses", displayValue: "Substatuses" },
  ];

  const lookupState = useSelector((state) => state.lookup.global);
  const localeState = useSelector((state) => state.locale);

  const [selectedLookupId, setSelectedLookupId] = useState(null);
  const [lookupTableValues, setLookupTableValues] = useState(null);
  const [selectedTableValueId, setSelectedTableValueId] = useState(null);
  const [isAddingTableValue, setIsAddingTableValue] = useState(false);
  const [selectedTableValueText, setSelectedTableValueText] = useState(null);
  const [codeText, setCodeText] = useState(null);
  const [selectedMatterTypeIds, setSelectedMatterTypeIds] = useState(null);

  const [listValues, setListValues] = useState(null);
  const [selectedListValueId, setSelectedListValueId] = useState(null);
  const [isAddingListValue, setIsAddingListValue] = useState(false);
  const [selectedListValueText, setSelectedListValueText] = useState(null);

  const matterTypes = getAllLookupValuesForSource("MatterTypes");

  useEffect(() => {
    const lookupValuesLocal = getAllLookupValuesForSource(selectedLookupId);
    setLookupTableValues(lookupValuesLocal);
  }, [lookupState, localeState, selectedLookupId]);

  const handleTableSelectionChange = (selected) => {
    setSelectedLookupId(selected);
    const lookupValuesLocal = getAllLookupValuesForSource(selected);
    setLookupTableValues(lookupValuesLocal);
    setSelectedTableValueId(null);
    setSelectedTableValueText(null);
    setIsAddingTableValue(false);
  };

  const handleTableValueSelectionChange = (selected) => {
    setSelectedTableValueId(selected);
    const lookupValue = lookupTableValues.find((value) => value.id === selected);
    const selectedLookupDisplayValue = lookupValue?.displayValue;
    setSelectedMatterTypeIds(lookupValue?.matterTypeIds);
    setSelectedTableValueText(selectedLookupDisplayValue);
    if (lookupValue.hasOwnProperty("code")) setCodeText(lookupValue.code);
    setIsAddingTableValue(false);

    const isListType = selectedLookupId === "ListTypes";
    isListType && setListValues(getAllListDisplayValues(selected));
  };

  const handleAddNewTableValueClick = () => {
    setSelectedTableValueId(null);
    setSelectedTableValueText("");
    setIsAddingTableValue(true);
  };

  const handleTableValueTextChange = (e) => {
    setSelectedTableValueText(e.target.value);
  };

  const handleCodeTextChange = (e) => {
    setCodeText(e.target.value);
  };

  // TODO: Add code to handle the onBlur event for the code text input, or merge with more generic handleValueTextBlur
  const handleTableValueTextBlur = async () => {
    const lookupTable = configurableLookups.find((lookup) => lookup.id === selectedLookupId);
    if (isAddingTableValue) {
      const response = await addLookupEntry(lookupTable.id, selectedTableValueText);
      if (response) {
        setSelectedTableValueId(response.id);
        setIsAddingTableValue(false);
      }
    } else {
      const selectedValue = lookupTableValues.find((value) => value.id === selectedTableValueId);
      updateLookupEntry(
        selectedLookupId,
        selectedTableValueId,
        "displayName",
        selectedValue.displayValue,
        selectedTableValueText,
        selectedValue.translationCode
      );
    }
  };

  const handleMatterTypeIdsChange = (selected, operation) => {
    const selectedMatterIdsCurrent = selectedMatterTypeIds ? [...selectedMatterTypeIds] : [];
    let selectedMatterIdsNew = null;
    if (operation === superSelectOperationTypes.ADD)
      selectedMatterIdsNew = selectedMatterTypeIds?.length > 0 ? [...selectedMatterTypeIds, selected] : [selected];
    else selectedMatterIdsNew = selectedMatterTypeIds.filter((id) => id !== selected);
    setSelectedMatterTypeIds(selectedMatterIdsNew);

    updateLookupEntry(
      selectedLookupId,
      selectedTableValueId,
      "matterTypeIds",
      selectedMatterIdsCurrent,
      selectedMatterIdsNew
    );
  };

  const handleTableValueDelete = () => {
    deleteLookupEntry(selectedLookupId, selectedTableValueId);
    setSelectedTableValueId(null);
    setSelectedTableValueText(null);
    setSelectedMatterTypeIds(null);
    setIsAddingTableValue(false);
  };

  //////////////////////
  // LIST VALUE HANDLERS
  const handleListValueSelectionChange = (selected) => {
    setSelectedListValueId(selected);
    const listValue = listValues.find((value) => value.id === selected);
    const selectedListDisplayValue = listValue?.displayValue;
    setSelectedListValueText(selectedListDisplayValue);
    setIsAddingListValue(false);
  };

  const handleAddNewListValueClick = () => {
    setSelectedListValueId(null);
    setSelectedListValueText("");
    setIsAddingListValue(true);
  };

  const handleListValueTextChange = (e) => {
    setSelectedListValueText(e.target.value);
  };

  const handleListValueTextBlur = async () => {
    // const looku = configurableLookups.find((lookup) => lookup.id === selectedLookupId);
    // if (isAddingTableValue) {
    //   const response = await addLookupEntry(lookupTable.id, selectedTableValueText);
    //   if (response) {
    //     setSelectedTableValueId(response.id);
    //     setIsAddingTableValue(false);
    //   }
    // } else {
    //   const selectedValue = lookupTableValues.find((value) => value.id === selectedTableValueId);
    //   updateLookupEntry(
    //     selectedLookupId,
    //     selectedTableValueId,
    //     "displayName",
    //     selectedValue.displayValue,
    //     selectedTableValueText,
    //     selectedValue.translationCode
    //   );
    // }
  };

  const handleListValueDelete = () => {
    // TODO: Need to add new API function to delete list values?
    //deleteListValueEntry(selectedLookupId, selectedTableValueId);
    setSelectedListValueId(null);
    setSelectedListValueText(null);
    setIsAddingListValue(false);
  };

  const renderTables = (
    <div className="lookup__column">
      <div className="column__title">{t("Lookup Table")}</div>
      <List
        id="lookups"
        options={configurableLookups}
        selectedOptionId={selectedLookupId}
        onChange={handleTableSelectionChange}
      />
    </div>
  );

  //console.log("lookupValues:", lookupValues);

  const renderValues = (
    <div className="lookup__column">
      <div className="column__title">{t("Table Values")}</div>
      <List
        id="lookup-values"
        options={lookupTableValues}
        selectedOptionId={selectedTableValueId}
        onChange={handleTableValueSelectionChange}
        cssClassNameModifier="lookup-values"
        showAvailableMatterTypes={true}
      />
      <button className="column__button" onClick={handleAddNewTableValueClick}>
        {t("Add New Value")}
      </button>
    </div>
  );

  const renderEdit = () => {
    if (!selectedTableValueId && !isAddingTableValue) return null;
    const selectedValue = lookupTableValues.find((value) => value.id === selectedTableValueId);
    const isReferenceType = selectedLookupId === "ReferenceTypes";
    const isSystemOwned = selectedValue.isSystemOwned;

    const isListType = selectedLookupId === "ListTypes";

    const renderValueDetails = () => {
      return (
        <div className="lookup__column">
          <div className="column__title">{t("Table Value Details")}</div>
          <div className="edit__row">
            <div className="edit__label">{t("Display Name")}</div>
            {isSystemOwned ? (
              <span>{selectedTableValueText}</span>
            ) : (
              <div className="edit__value">
                <input
                  value={selectedTableValueText}
                  onChange={handleTableValueTextChange}
                  onBlur={handleTableValueTextBlur}
                />
              </div>
            )}
          </div>
          {!isAddingTableValue && !isReferenceType && (
            <div className="edit__row">
              <div className="edit__label">{t("Valid Matter Types")}</div>
              <div className="edit__value">
                <SuperSelect
                  options={matterTypes}
                  selectedOptionIds={selectedMatterTypeIds}
                  isMultiValued={true}
                  onChangeMulti={handleMatterTypeIdsChange}
                />
              </div>
            </div>
          )}
          {(isReferenceType || isListType) && (
            <div className="edit__row">
              <div className="edit__label">{t("Code")}</div>
              <div className="edit__value">
                {isSystemOwned ? (
                  <span>{selectedTableValueText}</span>
                ) : (
                  <div className="edit__value">
                    <input value={codeText} onChange={handleCodeTextChange} /*onBlur={handleValueTextBlur}*/ />
                  </div>
                )}
              </div>
            </div>
          )}
          {isListType && (
            <>
              <div className="edit__row">
                <div className="edit__label">{t("Sort Values By Id?")}</div>
                <div className="edit__value">
                  <span>
                    {isSystemOwned ? (
                      selectedValue.sortValuesById ? (
                        "Yes"
                      ) : (
                        "No"
                      )
                    ) : (
                      <Checkbox isChecked={selectedValue.sortValuesById} />
                    )}
                  </span>
                </div>
              </div>
              <div className="edit__row">
                <div className="edit__label">{t("Multi Value?")}</div>
                <div className="edit__value">
                  <span>
                    {isSystemOwned ? (
                      selectedValue.multivalued ? (
                        "Yes"
                      ) : (
                        "No"
                      )
                    ) : (
                      <Checkbox isChecked={selectedValue.multivalued} />
                    )}
                  </span>
                </div>
              </div>
            </>
          )}

          {!isSystemOwned && (
            <div className="edit__row">
              <div className="edit__label">{t("Delete this table value")}</div>
              <div className="edit__value edit__value--control">
                <Delete
                  message="Delete this table entry"
                  onConfirm={handleTableValueDelete}
                  showTrashIconInstead={true}
                />
              </div>
            </div>
          )}
        </div>
      );
    };

    const renderListValues = () => {
      return (
        <>
          <div className="lookup__column">
            <div className="column__title">{t("List Values")}</div>
            <List
              id="list-values"
              options={listValues}
              selectedOptionId={selectedListValueId}
              onChange={handleListValueSelectionChange}
            />
            <button className="column__button" onClick={handleAddNewListValueClick}>
              {t("Add New Value")}
            </button>
          </div>

          <div className="lookup__column">
            <div className="column__title">{t("List Value Details")}</div>
            <div className="edit__row">
              <div className="edit__label">{t("Display Name")}</div>
              <div className="edit__value">
                <input
                  value={selectedListValueText}
                  onChange={handleListValueTextChange}
                  onBlur={handleListValueTextBlur}
                />
              </div>
            </div>
            <div className="edit__row">
              <div className="edit__label">{t("Delete this list value")}</div>
              <div className="edit__value edit__value--control">
                <Delete
                  message="Delete this list entry"
                  onConfirm={handleListValueDelete}
                  showTrashIconInstead={true}
                />
              </div>
            </div>
          </div>
        </>
      );
    };

    return (
      <>
        {renderValueDetails()}
        {isListType && renderListValues()}
      </>
    );
  };

  return (
    <div className="configuration__lookups">
      <div className="lookups__title">{t("Lookups")}</div>
      <div className="lookups__columns">
        {renderTables}
        {renderValues}
        {renderEdit()}
      </div>
    </div>
  );
}
