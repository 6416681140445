import { createSlice } from "@reduxjs/toolkit";
import { FormatTypes, searchViewTypes } from "utilities/constants";
import {
  compareByDisplayValueAsc,
  compareByDisplayValueDesc,
  filterOutOnId,
  idsAreEqual,
} from "utilities/stringAndArray";

function updatePagination(currentSearchIn, newPageNumber, numResults) {
  if (numResults === 0) {
    currentSearchIn.pagination.numResults = 0;
    currentSearchIn.pagination.totalPages = 0;
  }
  currentSearchIn.pagination.currentPage = newPageNumber;
  currentSearchIn.pagination.lowIndex = currentSearchIn.pagination.pageSize * currentSearchIn.pagination.currentPage;
  currentSearchIn.pagination.highIndex = Math.min(
    currentSearchIn.pagination.lowIndex + currentSearchIn.pagination.pageSize,
    currentSearchIn.pagination.numResults
  );
}

function updateCurrentSearch(state, modifiedSearchCopy) {
  state.searchesByViewType = state.searchesByViewType.map((s) =>
    s.viewType === modifiedSearchCopy.viewType ? modifiedSearchCopy : s
  );
  state.currentSearch = modifiedSearchCopy;
}

function doColumnSort(currentSearchLocal, fieldName, ascending) {
  currentSearchLocal.sort = { column: fieldName, ascending };
  // 1. Sort this column by display value

  const resultCol = currentSearchLocal.results.find((col) => col.fieldName === fieldName);
  const dataToSort = [...resultCol.data];
  if (ascending ? dataToSort.sort(compareByDisplayValueAsc) : dataToSort.sort(compareByDisplayValueDesc));
  // 2. Record the original order index attached to each record, now in a different order
  // e.g. 1,2,3,4,5 has become 4,2,1,5,3
  const resortedOriginalIndexArray = dataToSort.map((item) => item.index);
  // 3. For all columns, rebuild the records using the original order index in the new order
  currentSearchLocal.results.forEach((col) => {
    let sortedData = [];
    resortedOriginalIndexArray.forEach((sortIndex) => {
      sortedData.push(col.data.find((row) => row?.index === sortIndex));
    });
    col.data = sortedData;
    // Bonus step: reset the original order index to the new sort order. This is to provide "memory"
    // to the sort, so a series of multiple sorts will provide sorting of sub-records within records
    // having the same main sort (e.g. sorted by status [clicked last] and then by country name [clicked previously])
    col.data.forEach((row, index) => {
      row.index = index;
    });
  });
  return currentSearchLocal;
}

// We need to maintain separate instances of this slice's state for main search and other searches (e.g. connected matters)
// so we keep each as an object in an array, with the same initialiser
const initSearch = {
  logId: 0,
  results: [],
  rawResults: [],
  resultFields: [],
  images: [],
  selected: [],
  filterOnSelected: false,
  resultViewType: FormatTypes.LIST,
  sort: { column: "Matter_Name", ascending: false },
  projectId: 0,
  pagination: {
    pageSize: 15,
    totalPages: 0,
    currentPage: 0,
    lowIndex: 0,
    highIndex: 0,
    numResults: 0,
  },
  connectedMatterToAdd: null,
  abbreviated: false,
  renderData: [],
};

const viewTypes = [
  { ...initSearch, viewType: searchViewTypes.MAIN },
  { ...initSearch, viewType: searchViewTypes.CONNECTED_MATTERS },
  { ...initSearch, viewType: searchViewTypes.BATCH_IMPORT },
];

// Redux global state component for Simple Search
export const searchResultsSlice = createSlice({
  name: "searchResults",
  initialState: {
    searchesByViewType: viewTypes,
    currentSearch: viewTypes[0],
  },
  reducers: {
    // Indicates whether the main search panel in the header is being used, or a junior version within another panel
    setViewTypeSearchResults: (state, action) => {
      state.currentSearch = state.searchesByViewType.find((s) => s.viewType === action.payload);
    },

    initialiseSearch: (state, action) => {
      const initialisedSearch = { ...initSearch, viewType: action.payload };
      state.searchesByViewType = state.searchesByViewType.map((s) =>
        s.viewType === action.payload ? initialisedSearch : s
      );
      if (state.currentSearch.viewType === action.payload) state.currentSearch = initialisedSearch;
    },

    setResults: (state, action) => {
      const currentSearchLocal = { ...state.currentSearch };
      state.logId = action.payload.logId;
      const cols = action.payload.cols;
      const resultFields = cols.map((col) => col.displayName);
      const pageSize = action.payload.pageSize ?? currentSearchLocal.pagination.pageSize;

      currentSearchLocal.results = cols;
      currentSearchLocal.resultFields = resultFields;

      currentSearchLocal.pagination.pageSize = pageSize;
      currentSearchLocal.pagination.totalPages = Math.floor(
        cols[0].data.length / currentSearchLocal.pagination.pageSize
      );

      if (cols[0].data.length % currentSearchLocal.pagination.pageSize > 0)
        currentSearchLocal.pagination.totalPages += 1;

      currentSearchLocal.pagination.numResults = currentSearchLocal.results[0].data.length;

      updatePagination(currentSearchLocal, 0);

      updateCurrentSearch(state, currentSearchLocal);
    },
    modifySearchResult: (state, action) => {
      const currentSearchLocal = { ...state.currentSearch };
      const column = currentSearchLocal.results.find((result) => result.fieldName === action.payload.fieldName);
      const rowIndex = column.data.findIndex((row) => row.key === action.payload.key);
      column.data[rowIndex].displayValue = action.payload.displayValue;
      updateCurrentSearch(state, currentSearchLocal);
    },
    modifySearchResults: (state, action) => {
      const currentSearchLocal = { ...state.currentSearch };
      const column = currentSearchLocal.results.find((result) => result.fieldName === action.payload.fieldName);

      column.data.forEach((row) => {
        row.displayValue = action.payload.displayValue;
      });

      updateCurrentSearch(state, currentSearchLocal);
    },
    removeSearchResult: (state, action) => {
      const currentSearchLocal = {
        ...state.currentSearch,
        results: state.currentSearch.results.map((result) => ({
          ...result,
          data: result.data.filter((row) => !idsAreEqual(row.key, action.payload)),
        })),
      };

      updateCurrentSearch(state, currentSearchLocal);
    },
    clearResults: (state, action) => {
      const currentSearchLocal = { ...state.currentSearch };
      currentSearchLocal.results = [];
      currentSearchLocal.selected = [];
      currentSearchLocal.renderData = [];
      updatePagination(currentSearchLocal, 0, 0);
      updateCurrentSearch(state, currentSearchLocal);
    },
    resultViewChange: (state, action) => {
      const currentSearchLocal = { ...state.currentSearch };
      currentSearchLocal.resultViewType = action.payload;
      updateCurrentSearch(state, currentSearchLocal);
    },
    columnSortChange: (state, action) => {
      const currentSearchLocal = doColumnSort(
        { ...state.currentSearch },
        action.payload.column,
        action.payload.ascending
      );
      updateCurrentSearch(state, currentSearchLocal);
    },
    doInitialSort: (state) => {
      let currentSearchLocal = { ...state.currentSearch };
      // Use the smallest number of columns to sort, up to 5
      const maxCol = Math.min(currentSearchLocal.results.length, 5);
      for (let i = maxCol - 1; i >= 0; i--) {
        const column = currentSearchLocal.results[i].fieldName;
        console.log("sorting by column: ", column);
        currentSearchLocal = doColumnSort(currentSearchLocal, column, true);
      }
      updateCurrentSearch(state, currentSearchLocal);
    },
    pageChange: (state, action) => {
      const currentSearchLocal = { ...state.currentSearch };
      updatePagination(currentSearchLocal, action.payload);
      updateCurrentSearch(state, currentSearchLocal);
    },
    selectedChange: (state, action) => {
      const currentSearchLocal = { ...state.currentSearch };
      const id = action.payload.id.toString();
      if (currentSearchLocal.selected.includes(id)) {
        currentSearchLocal.selected = filterOutOnId(currentSearchLocal.selected, id);
      } else {
        if (action.payload.singleSelection) currentSearchLocal.selected = [id];
        else currentSearchLocal.selected.push(id);
      }

      updateCurrentSearch(state, currentSearchLocal);
    },
    selectAll: (state, action) => {
      const { isAllSelected, fieldName } = action.payload;
      const currentSearchLocal = { ...state.currentSearch };
      if (isAllSelected === false) currentSearchLocal.selected = [];
      else currentSearchLocal.selected = state.currentSearch.renderData.map((row) => row[fieldName]?.toString());
      updateCurrentSearch(state, currentSearchLocal);
    },
    setSelected: (state, action) => {
      const currentSearchLocal = { ...state.currentSearch };
      currentSearchLocal.selected = action.payload;
      updateCurrentSearch(state, currentSearchLocal);
    },
    setFilterOnSelected: (state, action) => {
      const currentSearchLocal = { ...state.currentSearch };
      currentSearchLocal.filterOnSelected = action.payload;
      updateCurrentSearch(state, currentSearchLocal);
    },
    setConnectedMatterToAdd: (state, action) => {
      const currentSearchLocal = { ...state.currentSearch };
      currentSearchLocal.connectedMatterToAdd = action.payload;
      updateCurrentSearch(state, currentSearchLocal);
    },
    toggleAbbreviated: (state) => {
      //debugger;
      const currentSearchLocal = { ...state.currentSearch };
      currentSearchLocal.abbreviated = !currentSearchLocal.abbreviated;
      updateCurrentSearch(state, currentSearchLocal);
    },
    setRenderData: (state, action) => {
      const currentSearchLocal = { ...state.currentSearch };
      currentSearchLocal.renderData = action.payload;
      updateCurrentSearch(state, currentSearchLocal);
    },
    clearImages: (state) => {
      const currentSearchLocal = { ...state.currentSearch };
      currentSearchLocal.images = [];
      updateCurrentSearch(state, currentSearchLocal);
    },
    addImage: (state, action) => {
      const currentSearchLocal = { ...state.currentSearch };
      currentSearchLocal.images.push(action.payload);
      updateCurrentSearch(state, currentSearchLocal);
    },
    clearSelected: (state, action) => {
      const currentSearchLocal = { ...state.currentSearch };
      currentSearchLocal.selected = [];
      updateCurrentSearch(state, currentSearchLocal);
    },
    setProjectId: (state, action) => {
      const currentSearchLocal = { ...state.currentSearch };
      currentSearchLocal.projectId = action.payload;
      updateCurrentSearch(state, currentSearchLocal);
    },
  },
});

export const {
  setViewTypeSearchResults,
  setResults,
  modifySearchResult,
  modifySearchResults,
  removeSearchResult,
  clearResults,
  initialiseSearch,
  resultViewChange,
  columnSortChange,
  doInitialSort,
  pageChange,
  selectedChange,
  selectAll,
  setSelected,
  setFilterOnSelected,
  setConnectedMatterToAdd,
  toggleAbbreviated,
  setRenderData,
  clearImages,
  addImage,
  clearSelected,
  setRawDataImportResults,
  updateImportStatus,
  setProjectId,
} = searchResultsSlice.actions;

export default searchResultsSlice.reducer;
